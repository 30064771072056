import 'components/RightSidebar/RightSidebar.scss';
import { Heading } from 'components/Typography';

import React, { ReactNode, useEffect } from 'react';
import cn from 'classnames';

interface Props {
  title: string;
  content: ReactNode;
  opened: boolean;
  onClose: () => void;
}

const RightSidebar: React.FC<Props> = ({ title, content, opened, onClose }) => {
  useEffect(() => {
    if (opened) {
      document.querySelector('body')?.style.setProperty('overflow', 'hidden');
    } else {
      document.querySelector('body')?.style.setProperty('overflow', 'auto');
    }
  }, [ opened ]);

  return (
    <>
      {opened && <div className="overlay" data-testid="overlay" />}

      <div className={cn('rightSidebar', { rightSidebar_opened: opened })} data-testid="right-sidebar">

        <div className="rightSidebar__title">
          <Heading variant="H3">{title}</Heading>

          <span className="rightSidebar__close" onClick={onClose} data-testid="right-sidebar-close" />
        </div>

        <div className="rightSidebar__content">
          {content}
        </div>
      </div>
    </>
  );
};

export default RightSidebar;
